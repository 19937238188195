import React from 'react';
import './bookmark.css';
import {Overlay, Tooltip} from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import {AccountService} from "../../../../services/account.service";
import {BsStar, BsStarFill} from "react-icons/bs";
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import {START_LOADING, STOP_LOADING} from "../../../../redux/actions/loader"


class Bookmark extends React.Component {
    constructor(props) {
        super(props);
        this.target = React.createRef(null);
        this.props.START_LOADING();
        this.state = {
            name: '',
            bookmarkList: [],
            checkboxValues: [],
            isSelected: false,
            hasData: true,
            currentPage: 1,
            isShow: false,
            selectedIdList: [],
            pageSelected: null,
            showTooltip: false,
            isSelectAll: false,
            showBookmark: false,
            isBookmarked: false,
            url: '',
            showBookmarkTooltip: false
        }
        this.refreshDataList = this.refreshDataList.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
        this.handleIconChange = this.handleIconChange.bind(this);

    }

    async componentDidMount() {
        const bmlist = await AccountService.getBookmark()
        if (bmlist) {
            this.handleIconChange(bmlist.data);
            this.setState({
                name: this.getCurrentWindowUrl(),
                url: this.getCurrentWindowUrl(),
                bookmarkList: bmlist.data,
                checkboxValues: new Array(this.state.bookmarkList.length).fill(false),
            });
        }

        document.addEventListener("mousedown", this.handleClickOutside);
        this.unlisten = this.props.history.listen((location) => {
            this.handleIconChange(this.state.bookmarkList)
            this.setState({
                name: this.getCurrentWindowUrl()
            })
        });
        this.props.STOP_LOADING();
    }

    componentWillUnmount() {
        document.removeEventListener("mousedown", this.handleClickOutside);
        this.unlisten();
    }

    getCurrentWindowUrl() {
        let url = window.location.href.trim("");
        return url.replace(/\/+$/, '');
    }

    //change URL
    handleIconChange(bookmarkList) {
        let urlList = []
        if (bookmarkList) {
            bookmarkList.forEach((item) => urlList.push(item.url))
            if (urlList.includes(this.getCurrentWindowUrl())) {
                this.setState({
                    isBookmarked: true
                })
            } else {
                this.setState({
                    isBookmarked: false
                })
            }
        }
    }

    handleBookmark() {
        const bookmark = !this.state.showBookmark
        this.setState({showBookmark: bookmark})

    }

    handleClickOutside = (event) => {
        const star = document.getElementById('star')
        const bookmarkBox = document.getElementById('bookmark-out')
        if (
            bookmarkBox &&
            !bookmarkBox.contains(event.target)
            && !star.contains(event.target)
        ) {
            this.setState({showBookmark: false});
        }
    };

    refreshDataList = (bookmarkList) => {
        if (bookmarkList) {
            if (bookmarkList.length !== 0) {
                this.setState({
                    hasData: true,
                    bookmarkList: bookmarkList
                })
                this.clearAllValue();
            } else {
                this.setState({
                    hasData: false,
                    checkboxValues: new Array(this.state.bookmarkList.length).fill(false),
                })
            }
        }
        this.setState({
            isSelected: false
        })
        this.handleIconChange(bookmarkList);
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.isShow !== prevState.isShow) {
            if (!this.state.showBookmark) {
                this.setState({
                    isSelected: false,
                    currentPage: 1,
                })
                this.clearAllValue();
                this.refreshDataList();
            } else {
                this.componentDidMount()
            }
        }
    }

    clearAllValue() {
        this.setState({
            checkboxValues: new Array(this.state.bookmarkList.length).fill(false),
            selectedIdList: [],
            isSelectAll: false,
        })
    }

    handleCheckboxChange = (event, index, id) => {
        //add Id to the list
        const newSelectedId = [...this.state.selectedIdList];
        if (event.target.checked) {
            newSelectedId.push(parseFloat(id));
            this.setState({selectedIdList: newSelectedId});
        } else {
            let index = newSelectedId.indexOf(id);
            if (index !== -1) {
                newSelectedId.splice(index, 1);
                this.setState({selectedIdList: newSelectedId});
            }
        }

        //change the display
        const newCheckboxValues = [...this.state.checkboxValues];
        newCheckboxValues[index] = event.target.checked;
        this.setState({checkboxValues: newCheckboxValues});

    };

    selectAllValue() {
        const newSelectedId = [...this.state.selectedIdList];
        const pageSelected = Array.from(document.getElementsByClassName("bookmark-checkbox"));
        pageSelected.forEach(item => newSelectedId.push(parseFloat(item.id)))
        this.setState({
            checkboxValues: new Array(this.state.bookmarkList.length).fill(true),
            selectedIdList: newSelectedId,
            isSelectAll: true,
        })

    }

    handleSelectAll() {
        this.selectAllValue();
    }

    handleUnselectAll() {
        this.clearAllValue();
    }

    handleSelect() {
        this.setState({
            isSelected: !this.state.isSelected,
        })
        this.clearAllValue();
    }

    async addBookmark() {
        const param = {
            name: this.state.name,
            url: this.getCurrentWindowUrl(),
        };
        let new_item = {
            "id": "",
            "name": param.name,
            "url": param.url,
            "timestamp": "",
            "accountId": ""
        }
        let newList = this.state.bookmarkList;
        const urlList = newList.map(bookmark_item => bookmark_item.url);
        let item_index = urlList.indexOf(param.url);
        if (item_index < 0) {
            newList.unshift(new_item)
        } else {
            newList[item_index] = new_item
        }
        this.refreshDataList(newList);
        await AccountService.addBookmark(param);
        this.setState({
            bookmarkList: (await AccountService.getBookmark()).data
        })
    }

    async deleteCurrentBookmark() {
        const list = this.state.bookmarkList;
        const deleteList = [];
        list.forEach((item) => {
            if (item.url === this.getCurrentWindowUrl()) {
                deleteList.push(item.id)
            }
        })
        let newList = list.filter(item => item.url !== this.getCurrentWindowUrl());
        this.refreshDataList(newList);
        if (deleteList.length !== 0) {
            await AccountService.deleteBookmarkByIds(deleteList);
        }
        this.setState({
            bookmarkList: (await AccountService.getBookmark()).data
        })
    }

    handleDelete = async () => {
        const list = this.state.bookmarkList;
        const selectedIdList = this.state.selectedIdList;
        const newList = [];
        if (selectedIdList.length !== 0) {
            list.forEach(item => {
                if (!selectedIdList.includes(item.id)) {
                    newList.push(item)
                }
            })
            this.setState({
                bookmarkList: newList
            })
            this.refreshDataList(newList);
            await AccountService.deleteBookmarkByIds(selectedIdList);
            this.setState({
                bookmarkList: (await AccountService.getBookmark()).data
            })
        } else {
            this.setState({
                showTooltip: true,
            })
            setTimeout(() => {
                this.setState({showTooltip: false});
            }, 2000);
        }
    };


    handleBookmarkTooltip() {
        this.setState({
            showBookmarkTooltip: true
        })
    }

    closeTooltip() {
        this.setState({
            showBookmarkTooltip: false
        })
    }

    render() {
        const {
            isSelected,
            showBookmark,
            showBookmarkTooltip,
            bookmarkList,
            checkboxValues,
            hasData,
            showTooltip,
            name,
            isSelectAll
        } = this.state;

        return (
            <>
                <div className="star" id='star' onClick={() => this.handleBookmark()} onMouseOver={() => this.handleBookmarkTooltip()} onMouseLeave={() => this.closeTooltip()}>
                    {this.state.isBookmarked ? <BsStarFill/>:<BsStar/>}   
                    {showBookmarkTooltip && <div id='bm-tooltip'>Bookmark</div>}
                </div>
                {showBookmark &&
                    <div className='bookmark-out' id='bookmark-out'>
                        <div className='bookmark-box'>
                            <div className='bm-list-title'><b>Create Bookmarks</b></div>
                            <div className='input-bm-div'>
                                <label>Name</label>
                                <div className='input-bm-item'>
                                    <input
                                        type='text'
                                        onClick={(e) => e.target.select()}
                                        value={name}
                                        id='bm-name'
                                        onChange={(e) => this.setState({
                                            name: e.currentTarget.value
                                        })}
                                        onKeyDown={(event) => {
                                            if (event.key === "Enter") {
                                                this.addBookmark();
                                                this.handleBookmark();
                                            }
                                        }
                                        }></input>
                                    <div className='current-bm-buttons'>
                                        <Button variant='dark' className="bookmark-button" onClick={() => {
                                            this.addBookmark();
                                            this.handleBookmark();
                                        }}>Add</Button>
                                        <Button variant='outline-light' className="bookmark-button"
                                                onClick={() => {
                                                    this.deleteCurrentBookmark();
                                                    this.handleBookmark();
                                                }}>Remove</Button>
                                    </div>
                                </div>
                            </div>

                            {!hasData ? <>
                                    <div> There is no bookmark.</div>
                                </> :
                                <>
                                    <div className="bookmark-body-header">
                                        <div className='bm-list-title'>
                                            <div><b>Bookmark List</b></div>
                                            <div className="bookmark-select-checkbox-div">
                                                <input
                                                    className="bookmark-select-checkbox"
                                                    type="checkbox"
                                                    checked={this.state.isSelected}
                                                    onChange={() => this.handleSelect()}
                                                />
                                                <p>Modify</p>
                                            </div>
                                        </div>

                                        {this.state.isSelected && (
                                            <div className="handle-list-div">
                                                {!isSelectAll ?
                                                    <Button  variant="dark"
                                                            className="select-all-btnbm bookmark-button"
                                                            onClick={() => this.handleSelectAll()}>
                                                        Select All
                                                    </Button> :
                                                    <Button  variant="dark"
                                                            className="unselect-all-btn bookmark-button"
                                                            onClick={() => this.handleUnselectAll()}>
                                                        Unselect
                                                    </Button>
                                                }

                                                <Button  ref={this.target}
                                                        className="delete-bookmark-btn bookmark-button" variant="outline-light"
                                                        onClick={() => this.handleDelete()}>
                                                    Delete
                                                </Button>
                                                <Overlay target={this.target.current} show={showTooltip}
                                                         placement="bottom">
                                                    {(props) => (
                                                        <Tooltip id="del-tooltip" {...props}>
                                                            <p>Select at least one item to delete.</p>
                                                        </Tooltip>
                                                    )}
                                                </Overlay>

                                            </div>
                                        )}
                                    </div>
                                    <div className='bm-list'>
                                        {bookmarkList.map((path, index) => (
                                            <div className="bm-item-div" key={index}>
                                                {isSelected && (
                                                    <div  className="bookmark-checkbox">
                                                        <input
                                                        id={path.id}  
                                                        type="checkbox"
                                                        checked={checkboxValues[index] || false}
                                                        onChange={(event) => this.handleCheckboxChange(event, index, path.id)}
                                                    />
                                                    </div>
                                                    

                                                )}
                                                <a id="bm-link" className="bookmark-list-item" href={path.url}>          
                                                    {path.name}
                                                </a>
                                            </div>
                                        ))}
                                    </div>
                                </>
                            }
                        </div>
                    </div>
                }

            </>
        );
    }

}


export default withRouter(connect(null, {START_LOADING, STOP_LOADING})(Bookmark));