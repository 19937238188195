import React from 'react';
import {withRouter} from 'react-router-dom';
import './forgot-password-page.css';
import {Helmet} from 'react-helmet';
import {connect} from 'react-redux';
import {START_LOADING, STOP_LOADING} from '../../../redux/actions/loader';
import {Link} from 'react-router-dom';
import {Formik, Field, Form, ErrorMessage} from 'formik';
import * as Yup from 'yup';
import {AccountService} from '../../../services/account.service';
import ReCAPTCHA from 'react-google-recaptcha';

const initialValues = {
    email: '',
    recaptcha: ''
};

/* local: */
// const google_recaptcha_api_key = "6LcpkKsUAAAAADxV8pSA8Pklfkl-UUxUV3xOuqKE";
const google_recaptcha_api_key = process.env.REACT_APP_GOOGLE_RECAPTCHA_API_KEY;

class ForgotPassword extends React.Component {
    constructor(props) {
        super(props);
        this.props.START_LOADING();
        this.onSubmit = this.onSubmit.bind(this);
        this.state = {
            isForgotSuccess: false
        }
    }

    componentDidMount() {
        this.props.STOP_LOADING();
    }

    validationSchema = Yup.object().shape({
        email: Yup.string().email('Email is invalid').required('Email is required'),
        recaptcha: Yup.string('The reCAPTCHA was invalid.').required('The reCAPTCHA was invalid.')
    });

    onSubmit({email}, {setSubmitting, setErrors}) {
        AccountService.forgotPassword(email)
            .then((res) => {
                setSubmitting(false);

                if (res.status === 400) {
                    if (res.data?.email) {
                        setErrors({email: res.data?.email});
                        return;
                    }
                }

                // Forgot password success.
                this.setState({
                    isForgotSuccess: true
                });
            })
            .catch(error => {
                //TODO alert or show modal error
            })
            .finally(() => setSubmitting(false));
    }

    render() {
        const {isForgotSuccess} = this.state;

        return (
            <div className='forgot-password-page'>
                <Helmet>
                    <title> Forgot Password | ScriptureCast</title>
                </Helmet>

                <div className="box-style1">
                    {!isForgotSuccess && (
                        <Formik
                            initialValues={initialValues}
                            validationSchema={this.validationSchema}
                            onSubmit={this.onSubmit}
                        >
                            {({errors, touched, isSubmitting, setFieldValue}) => (
                                <Form>
                                    <h3 className="card-header">Forgot Password</h3>
                                    <div className="card-body">
                                        <div className="form-group">
                                            <label>Email</label>
                                            <Field
                                                name="email"
                                                type="text"
                                                className={
                                                    'form-control' +
                                                    (errors.email && touched.email ? ' is-invalid' : '')
                                                }
                                            />
                                            <ErrorMessage
                                                name="email"
                                                component="div"
                                                className="error-yellow"
                                            />
                                        </div>

                                        {/* Google Recapcha */}
                                        <div className="form-group">
                                            <div className="fields-container">
                                                <ReCAPTCHA
                                                    style={{display: 'inline-block'}}
                                                    theme="light"
                                                    size="normal"
                                                    sitekey={google_recaptcha_api_key}
                                                    onChange={(response) => {
                                                        setFieldValue('recaptcha', response)
                                                    }}
                                                />
                                                {errors.recaptcha && touched.recaptcha && (
                                                    <div className="error-yellow">
                                                        {errors.recaptcha}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div className="flex gap-1 align-center">
                                            <div>
                                                <button
                                                    type="submit"
                                                    disabled={isSubmitting}
                                                    className="button button-style1"
                                                >
                                                    {isSubmitting && (
                                                        <span className="spinner-border spinner-border-sm mr-1"></span>
                                                    )}
                                                    Submit
                                                </button>
                                            </div>
                                            <div>
                                                <Link to="login" className="link-style1">
                                                    Cancel
                                                </Link>
                                            </div>


                                        </div>
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    )}
                    {isForgotSuccess && (
                        <>
                            <h3 className="card-header">Forgot Password</h3>
                            <div className="card-body">
                                <div className="form-row">
                  <span>
                    Please check your email for password reset instructions.
                  </span>
                                </div>
                            </div>
                        </>
                    )}
                </div>
            </div>
        );
    }
}

export default withRouter(
    connect(null, {START_LOADING, STOP_LOADING})(ForgotPassword)
);
