import React from 'react';
import {withRouter} from 'react-router-dom';
import './change-password-page.css';
import {Helmet} from 'react-helmet';
import {connect} from 'react-redux';
import {START_LOADING, STOP_LOADING} from '../../../redux/actions/loader';
import {Formik, Field, Form, ErrorMessage} from 'formik';
import * as Yup from 'yup';
import {AccountService} from '../../../services/account.service';
import CustomModal from '../../common/reusable/custom-modal/custom-modal';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faEye, faEyeSlash} from '@fortawesome/free-solid-svg-icons';

const initialValues = {
    currentPassword: '',
    password: '',
    confirmPassword: '',
};

class ChangePassword extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            errorFromBackend: null,
            isShowModal: false,
            showCurrentPassword: false,
            showNewPassword: false,
            showConfirmPassword: false
        };
        this.props.START_LOADING();
        this.onSubmit = this.onSubmit.bind(this);
        this.handleShowModal = this.handleShowModal.bind(this);
        this.handleCloseModal = this.handleCloseModal.bind(this);
        this.handleOKButton = this.handleOKButton.bind(this);
        this.handleShowCurrentPasswordToggle = this.handleShowCurrentPasswordToggle.bind(this)
        this.handleShowNewPasswordToggle = this.handleShowNewPasswordToggle.bind(this)
        this.handleShowConfirmPasswordToggle = this.handleShowConfirmPasswordToggle.bind(this)
    }

    handleShowCurrentPasswordToggle(event) {
        this.setState((prevState) => ({
            showCurrentPassword: !prevState.showCurrentPassword,
        }));
    }

    handleShowNewPasswordToggle(event) {
        this.setState((prevState) => ({
            showNewPassword: !prevState.showNewPassword,
        }));
    }

    handleShowConfirmPasswordToggle(event) {
        this.setState((prevState) => ({
            showConfirmPassword: !prevState.showConfirmPassword,
        }));
    }

    showErrorMessageFromBackend(errorsYup, touchedYup) {
        const {errorFromBackend} = this.state;
        if (errorFromBackend) {
            const errorMessage = this.state.errorFromBackend;
            return <div className="error-yellow">{errorMessage}</div>;
        }
    }

    componentDidMount() {
        this.props.STOP_LOADING();
    }

    handleCancel() {
        this.props.history.goBack();
    }

    onSubmit(
        {currentPassword, password, confirmPassword},
        {setSubmitting, setErrors}
    ) {
        this.setState({
            errorFromBackend: null,
        });

        this.props.START_LOADING();
        AccountService.changePassword(currentPassword, password, confirmPassword)
            .then((res) => {
                setSubmitting(false);
                this.props.STOP_LOADING();

                if (res.status === 400) {
                    if (res.data?.currentPassword) {
                        setErrors({currentPassword: res.data?.currentPassword});
                        return;
                    }
                }

                // Backend - internal server error occured.
                if (res.status === 500) {
                    this.setState({
                        errorFromBackend:
                            'We are sorry. An server error occurred. Please try again or contact the administrator.',
                    });

                    return;
                }

                // On Registration Successful
                this.handleShowModal();

            })
            .catch((error) => {
                setSubmitting(false);
                this.props.STOP_LOADING();
            });
    }

    getButtonsGroup(isSubmitting) {
        return (
            <div className="flex gap-1 align-center">
                <button
                    type="submit"
                    disabled={isSubmitting}
                    className="button button-style1"
                >
                    {isSubmitting && (
                        <span className="spinner-border spinner-border-sm mr-1"></span>
                    )}
                    Change Password
                </button>
                <div className='link-style1' onClick={() => this.handleCancel()}>
                    Cancel
                </div>
            </div>
        );
    }

    validationSchema = Yup.object().shape({
        currentPassword: Yup.string()
            .min(6, 'Password must be at least 6 characters')
            .required('Password is required'),
        password: Yup.string()
            .min(6, 'Password must be at least 6 characters')
            .required('Password is required'),
        confirmPassword: Yup.string()
            .oneOf([Yup.ref('password'), null], 'Passwords must match')
            .required('Confirm Password is required'),
    });

    handleCloseModal() {
        this.setState({
            isShowModal: false,
        });
    }

    handleShowModal() {
        this.setState({
            isShowModal: true,
        });
    }

    handleOKButton() {
        this.props.history.goBack();
    }

    render() {
        const {errorFromBackend, isShowModal, showCurrentPassword, showNewPassword, showConfirmPassword} = this.state;
        return (
            <>
                <Helmet>
                    <title> Change Password | ScriptureCast</title>
                </Helmet>

                <CustomModal
                    isShowModal={isShowModal}
                    modalHeading={'Password Updated'}
                    modalBody={
                        'Your password has been changed successfully.'
                    }
                    buttonName={'OK'}
                    handleCloseModal={this.handleCloseModal}
                    handleOKButton={this.handleOKButton}
                ></CustomModal>

                <div className='change-password-content box-style1'>
                    <h3 className="card-header">Change Password</h3>
                    <div className="card-body">
                        <Formik
                            initialValues={initialValues}
                            validationSchema={this.validationSchema}
                            onSubmit={this.onSubmit}
                        >
                            {({errors, touched, isSubmitting}) => (
                                <Form>
                                    <div className="form-group eye-position">
                                        <label>Current Password</label>

                                        <Field
                                            name="currentPassword"
                                            type={showCurrentPassword ? 'text' : 'password'}
                                            className={
                                                'form-control' +
                                                (errors.currentPassword && touched.currentPassword
                                                    ? ' is-invalid'
                                                    : '')
                                            }
                                        />
                                        <button type="button" className="show-password-icon4"
                                                onClick={this.handleShowCurrentPasswordToggle}>
                                            <FontAwesomeIcon icon={showCurrentPassword ? faEyeSlash : faEye}/>
                                        </button>
                                        <ErrorMessage
                                            name="currentPassword"
                                            component="div"
                                            className="error-yellow"
                                        />

                                    </div>
                                    <div className="form-group">
                                        <label>New Password</label>
                                        <Field
                                            name="password"
                                            type={showNewPassword ? 'text' : 'password'}
                                            className={
                                                'form-control' +
                                                (errors.password && touched.password
                                                    ? ' is-invalid'
                                                    : '')
                                            }
                                        />
                                        <ErrorMessage
                                            name="password"
                                            component="div"
                                            className="error-yellow"
                                        />
                                        <button type="button" className="show-password-icon4"
                                                onClick={this.handleShowNewPasswordToggle}>
                                            <FontAwesomeIcon icon={showNewPassword ? faEyeSlash : faEye}/>
                                        </button>
                                    </div>
                                    <div className="form-group">
                                        <label>Confirm New Password</label>
                                        <Field
                                            name="confirmPassword"
                                            type={showConfirmPassword ? 'text' : 'password'}
                                            className={
                                                'form-control' +
                                                (errors.confirmPassword && touched.confirmPassword
                                                    ? ' is-invalid'
                                                    : '')
                                            }
                                        />
                                        <ErrorMessage
                                            name="confirmPassword"
                                            component="div"
                                            className="error-yellow"
                                        />
                                        <button type="button" className="show-password-icon4"
                                                onClick={this.handleShowConfirmPasswordToggle}>
                                            <FontAwesomeIcon icon={showConfirmPassword ? faEyeSlash : faEye}/>
                                        </button>
                                    </div>
                                    <>{this.getButtonsGroup(isSubmitting)}</>
                                    {errorFromBackend && this.showErrorMessageFromBackend()}
                                </Form>
                            )}
                        </Formik>
                    </div>
                </div>
            </>
        );
    }
}

export default withRouter(
    connect(null, {START_LOADING, STOP_LOADING})(ChangePassword)
);
