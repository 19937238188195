import {useState, useRef, useCallback, useEffect} from 'react';
import {useHistory, useLocation} from "react-router-dom";
import Volume from './Volume';
import ProgressBar from './ProgressBar';
import React from 'react';
import {Helmet} from 'react-helmet';
import {ReactComponent as Fw15} from './data/icons/forward-15.svg';
import {ReactComponent as Bw15} from './data/icons/back-15.svg';
import {ReactComponent as Play} from './data/icons/play.svg';
import {ReactComponent as NextT} from './data/icons/next.svg';
import {ReactComponent as Previous} from './data/icons/previous.svg';
import {ReactComponent as Pause} from './data/icons/pause.svg';
import {ReactComponent as Repeat} from './data/icons/repeat.svg';
import {ReactComponent as Suffle} from './data/icons/suffle.svg';
import {ReactComponent as Repeated} from './data/icons/repeated.svg';
import {ReactComponent as Suffled} from './data/icons/suffled.svg';
import {BsFillBellFill, BsFillPlayCircleFill, BsPlayFill} from 'react-icons/bs';
import {AccountService} from "../../../services/account.service";
import CustomModal3buttons from '../../common/reusable/custom-modal/custom-modal-3buttons';
import {toast} from 'react-toastify';


const AudioPlayer = (tracks_info) => {
    const location = useLocation();
    const tracks = tracks_info.tracks;
    const [trackIndex, setTrackIndex] = useState(0);
    const [currentTrack, setCurrentTrack] = useState(tracks[trackIndex]);
    const progressBarRef = useRef();
    const audioRef = useRef();
    const [timeProgress, setTimeProgress] = useState(0);
    const [duration, setDuration] = useState(0);
    const [isPlaying, setIsPlaying] = useState(false);
    const account_service = AccountService.userValue;
    const [isSubscribed, setIsSubscribed] = useState(account_service ? account_service.data.podcastSubscriber : false);
    const isLogin = account_service ? true : false;
    const [suffled, setSuffle] = useState(false);
    const [repeated, setRepeat] = useState(false);
    const playAnimationRef = useRef();
    const togglePlayPause = () => {
        setIsPlaying((prev) => !prev);
    };
    const repeat = useCallback(() => {
        if (audioRef.current) {
            const currentTime = audioRef.current.currentTime;
            setTimeProgress(currentTime);
            progressBarRef.current.value = currentTime;
            progressBarRef.current.style.setProperty(
                '--range-progress',
                `${(progressBarRef.current.value / duration) * 100}%`
            );

            playAnimationRef.current = requestAnimationFrame(repeat);
        }
    }, [audioRef, duration, progressBarRef, setTimeProgress]);

    const skipForward = () => {
        audioRef.current.currentTime += 15;
    };

    const skipBackward = () => {
        audioRef.current.currentTime -= 15;
    };

    const handlePrevious = () => {
        if (trackIndex === 0) {
            let lastTrackIndex = tracks.length - 1;
            setTrackIndex(lastTrackIndex);
            setCurrentTrack(tracks[lastTrackIndex]);
        } else {
            setTrackIndex((prev) => prev - 1);
            setCurrentTrack(tracks[trackIndex - 1]);
        }
    };

    const onLoadedMetadata = () => {
        if (audioRef.current) {
            const seconds = audioRef.current.duration;
            setDuration(seconds);
            progressBarRef.current.max = seconds;
        }
    }

    const handleSuffle = () => {
        setSuffle(!suffled);
    }

    const handleRepeat = () => {
        setRepeat(!repeated);
    }

    useEffect(() => {
        if (audioRef.current) {
            if (isPlaying) {
                audioRef.current.play();

            } else {
                audioRef.current.pause();
            }
            playAnimationRef.current = requestAnimationFrame(repeat);
        }
    }, [isPlaying, audioRef, repeat]);

    const handlePlayNew = (index) => {
        setIsPlaying(false);
        handlePlay(index);
    }

    const handlePlay = (index) => {
        if (index !== trackIndex) {
            audioRef.current.src = tracks[index].src;
            setTrackIndex(index);
            setCurrentTrack(tracks[index]);
            setIsPlaying(true);
        } else {
            setIsPlaying((prev) => !prev);
        }
    }

    const handleNext = () => {
        if (suffled) {
            const max = tracks.length;
            const current = trackIndex;
            let random = 0;
            while (true) {
                random = Math.floor(Math.random() * (max));
                if (random !== current) {
                    break;
                }
            }
            setTrackIndex(random);
            handlePlay(random);
        } else {
            if (trackIndex >= tracks.length - 1) {
                setTrackIndex(0);
                handlePlay(0);
            } else {
                setTrackIndex(trackIndex + 1);
                handlePlay(trackIndex + 1);
            }
        }
    };

    const handleEnd = () => {
        if (repeated) {
            audioRef.current.play();
        } else {
            handleNext();
        }
    };
    const formatTime = (time) => {
        if (time && !isNaN(time)) {
            const minutes = Math.floor(time / 60);
            const formatMinutes =
                minutes < 10 ? `0${minutes}` : `${minutes}`;
            const seconds = Math.floor(time % 60);
            const formatSeconds =
                seconds < 10 ? `0${seconds}` : `${seconds}`;
            return `${formatMinutes}:${formatSeconds}`;
        }
        return '00:00';
    };

    let history = useHistory();
    const [showLogin, setShowLogin] = useState(false);
    const [showUnsubscribe, setShowUnsubscribe] = useState(false);

    const handleLoginButton = (fromLink) => {
        history.push({
            pathname: "/account/login",
            state: {fromLink: fromLink}
        });
    }

    const handleCloseModal = () => {
        setShowLogin(false);
    }
    const handleRegisterButton = () => {
        history.push({
            pathname: "/account/register",
        });
    }

    const handleUnsubscribe = () => {
        AccountService.podcastUnsubscribe();
        setIsSubscribed(false);
        setShowUnsubscribe(false);
        toast('Subscription Removed', {
            position: 'bottom-left',
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            theme: "dark"
        });
    }
    const handleSubscribe = () => {
        if (!isLogin) {
            setShowLogin(true);
        } else {
            if (isSubscribed) {
                setShowUnsubscribe(true);
            } else {
                AccountService.podcastSubscribe();
                setIsSubscribed(true);
                toast('Subscription Added', {
                    position: 'bottom-left',
                    autoClose: 2000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: false,
                    theme: "dark"
                });
            }
        }
    }
    const handleClose = () => {
        setShowUnsubscribe(false);
    }

    useEffect(() => {
        return () => {
            // Cleanup function to remove reference
            audioRef.current = null;
        };
    }, []);


    return (
        <>
            <CustomModal3buttons
                isShowModal={showLogin}
                modalHeading={'Subscribe'}
                modalBody={
                    'You must has an account to subscribe to The Shepherd and The Shrink Podcast.'
                }
                firstButton={'Sign in'}
                secondButton={'Sign up'}
                handleFirstButton={() => handleLoginButton(location.pathname)}
                handleSecondButton={() => handleRegisterButton()}
                handleCloseModal={() => handleCloseModal()}
            ></CustomModal3buttons>
            <CustomModal3buttons
                isShowModal={showUnsubscribe}
                modalHeading={'Unsubscribe'}
                modalBody={
                    'Unsubscribe from The Shepherd and The Shrink Podcast?'
                }
                firstButton={'Cancel'}
                secondButton={'Unsubscribe'}
                handleFirstButton={() => handleClose()}
                handleSecondButton={() => handleUnsubscribe()}
                handleCloseModal={() => handleClose()}
            ></CustomModal3buttons>
            <Helmet>
                <title> The Shepherd and The Shrink Podcast | ScriptureCast®</title>
            </Helmet>

            <audio
                src={currentTrack.src}
                ref={audioRef}
                onLoadedMetadata={onLoadedMetadata}
                onEnded={handleEnd}/>
            <div className="podcast-page">

                <div className='pc-text-box'>
                    <div className="podcast-header">
                        <h1 className="site-description-long-width">The Shepherd and The Shrink Podcast</h1>
                        <h2 className="site-description-long-width">An Outreach of ScriptureCast&reg;</h2>
                        <p className="podcast-subheader">
                            Amplify your Spiritual Growth One Topic at a Time
                        </p>
                    </div>

                    <div className="audio-player-container">
                        <div className="audio-player">
                            <div className="audio-info">
                                {/* change length of title */}
                                <div className="text1">
                                    <div
                                        className={`title1 ${(isPlaying && currentTrack.title.length > 60) ? "title-play" : ""}`}>{currentTrack.title}</div>
                                    <div>{currentTrack.author}</div>

                                </div>

                                <div className="progressBar">
                                    <ProgressBar {...{progressBarRef, audioRef, timeProgress, duration}}/>
                                </div>

                                <div className='timer-control'>

                                    <div className="timer">
                                        <div className="time">{formatTime(timeProgress)}/{formatTime(duration)}</div>
                                        <Volume {...{audioRef}}/>
                                    </div>

                                    <div className="controls">
                                        <button id="suffle-button" className="player-button" onClick={handleSuffle}>
                                            {suffled ? <Suffled/> : <Suffle/>}
                                        </button>
                                        <button onClick={handlePrevious}>
                                            <Previous/>
                                        </button>
                                        <button onClick={skipBackward}>
                                            <Bw15/>
                                        </button>

                                        <button onClick={togglePlayPause}>
                                            {isPlaying ? <Pause className="play-button"/> :
                                                <Play className="play-button"/>}
                                        </button>
                                        <button onClick={skipForward}>
                                            <Fw15/>
                                        </button>
                                        <button onClick={handleNext}>
                                            <NextT/>
                                        </button>

                                        <button id="repeat-button" onClick={handleRepeat}>
                                            {repeated ? <Repeated/> : <Repeat/>}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='font-size-large'>Latest Released</div>
                    <div className="newest-tracks">
                        {tracks.length > 0 &&
                            <div className="newest" onClick={() => {
                                handlePlayNew(0)
                            }}>
                                <div>
                                    <div className="newest-title">{tracks[0].title}</div>
                                    <div>{tracks[0].author}</div>
                                    <div>{tracks[0].length} &#x25cf; {tracks[0].date}</div>
                                </div>
                                <div className="square-play">
                                    <BsPlayFill/>
                                </div>

                            </div>
                        }
                        {tracks.length > 1 &&
                            <div className="newest" onClick={() => {
                                handlePlayNew(1)
                            }}>
                                <div>
                                    <div className="newest-title">{tracks[1].title}</div>
                                    <div>{tracks[1].author}</div>
                                    <div>{tracks[1].length} &#x25cf; {tracks[1].date}</div>
                                </div>
                                <div className="square-play">
                                    <BsPlayFill/>
                                </div>
                            </div>
                        }
                    </div>

                    <div className="flex gap-1">
                        <div className='font-size-large'>All Episodes</div>
                        {!isSubscribed ? <button className="subscribe-button subscribe" onClick={() => {
                                handleSubscribe()
                            }}>Subscribe</button>
                            :
                            <button className="subscribe-button unsubscribe" onClick={() => {
                                handleSubscribe()
                            }}><BsFillBellFill className="bell"/> Subscribed</button>
                        }

                    </div>
                    <div className="podcast-list">
                        {tracks.map((track, index) => (
                            <div
                                key={index}
                                className={trackIndex === index ? 'selected' : ''}
                                onClick={() => {
                                    handlePlay(index);
                                }}>
                                <div className="episode">
                                    <div className="podcast"><BsFillPlayCircleFill className="play-icon"/> {track.title}
                                    </div>
                                    <div className="ep-grid">
                                        <div>{track.author}</div>
                                        <div>Published Date: {track.date}</div>
                                        <div>Length: {track.length}</div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>

                </div>
            </div>

        </>

    );
};
export default AudioPlayer;



